import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import './Logo.css';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 453.79 68.34">
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="399.69"
            y1="-42.15"
            x2="384.19"
            y2="-20.65"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#fff" />
            <stop offset="1" />
          </linearGradient>
        </defs>
        <g className="cls-1">
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                className="cls-2"
                d="M439.72,1H234.81V54.27a14.07,14.07,0,0,0,14.07,14.07H453.79V15.09A14.07,14.07,0,0,0,439.72,1Zm-139,37.35a12.87,12.87,0,0,1-5.15,1H252v12a4.47,4.47,0,0,1-1.42,3.34,4.62,4.62,0,0,1-3.3,1.38,4.69,4.69,0,0,1-4.71-4.72V18a4.68,4.68,0,0,1,4.71-4.71h48.21a12.87,12.87,0,0,1,5.15,1,13.75,13.75,0,0,1,4.23,2.8,12.93,12.93,0,0,1,2.85,4.13,13.17,13.17,0,0,1,0,10.21,12.93,12.93,0,0,1-2.85,4.13A13.75,13.75,0,0,1,300.68,38.36Zm39.13,12a6.87,6.87,0,1,1,6.86-6.87A6.87,6.87,0,0,1,339.81,50.33Zm34.25,4.54a4.54,4.54,0,0,1-3.34,1.37A4.42,4.42,0,0,1,367,54.38L344.09,23.82a21.36,21.36,0,0,0-1.72-1.72,2.87,2.87,0,0,0-4.23,0,13.69,13.69,0,0,0-1.62,1.72L313.43,54.48a4.69,4.69,0,0,1-8.35-2.95,4.09,4.09,0,0,1,1-2.85l23.88-32.13a12.56,12.56,0,0,1,4.42-3.69,12.93,12.93,0,0,1,11.5-.14,11.44,11.44,0,0,1,4.32,3.53l23.88,31.94a6.93,6.93,0,0,1,1,1.52,4.25,4.25,0,0,1,.4,1.82A4.58,4.58,0,0,1,374.06,54.87ZM444.38,20a6.09,6.09,0,0,1-1.33,1.67q-5.4,4.71-9.92,8.1a64.79,64.79,0,0,1-8.5,5.5,36.84,36.84,0,0,1-7.56,3.1,26,26,0,0,1-6.93,1v12a4.72,4.72,0,1,1-9.43,0v-12a26.79,26.79,0,0,1-7.27-1,35.22,35.22,0,0,1-7.42-3.1,66.16,66.16,0,0,1-8.31-5.45q-4.41-3.33-9.92-8.15A6.09,6.09,0,0,1,366.46,20a4.26,4.26,0,0,1-.54-2.07,4.69,4.69,0,0,1,4.72-4.71,4,4,0,0,1,1.77.39,10.88,10.88,0,0,1,1.57,1q6.28,5.51,10.56,8.6a42.83,42.83,0,0,0,7.67,4.57,22.66,22.66,0,0,0,6.43,1.82,61.79,61.79,0,0,0,6.88.34,67.59,67.59,0,0,0,6.78-.3,20.66,20.66,0,0,0,6.29-1.76,44.07,44.07,0,0,0,7.62-4.57q4.26-3.1,10.66-8.7a5.22,5.22,0,0,1,3.34-1.37A4.68,4.68,0,0,1,444.92,18,4.26,4.26,0,0,1,444.38,20ZM295.92,22.69H252V30h43.88a3.73,3.73,0,0,0,2.6-1,3.51,3.51,0,0,0,0-5.16A3.65,3.65,0,0,0,295.92,22.69Z"
              />
              <path
                className="cls-3"
                d="M248.88,64.34a10.08,10.08,0,0,1-10.07-10.07v-1a8.74,8.74,0,0,0,8.51,6.79,8.62,8.62,0,0,0,6.07-2.5A8.47,8.47,0,0,0,256,51.38v-8h39.49A17,17,0,0,0,302.26,42a18,18,0,0,0,5.44-3.61,17.06,17.06,0,0,0,3.73-5.4,17.23,17.23,0,0,0,0-13.42,17,17,0,0,0-3.73-5.39,17.82,17.82,0,0,0-5.44-3.61,17,17,0,0,0-6.73-1.35H247.32a8.54,8.54,0,0,0-6.17,2.55A8.44,8.44,0,0,0,238.81,16V5H439.72a10.08,10.08,0,0,1,10.07,10.08V64.34Zm114.9-7.56a8.71,8.71,0,0,0,13.1.92,8.55,8.55,0,0,0,2.56-6.17,8.14,8.14,0,0,0-.79-3.53,10.28,10.28,0,0,0-1.46-2.3l-23.8-31.84a15.5,15.5,0,0,0-5.8-4.75,17,17,0,0,0-20.87,5.06L303,46.16a8.16,8.16,0,0,0-1.87,5.37A8.69,8.69,0,0,0,316.51,57l.12-.16,13.05-17.33a10.91,10.91,0,1,0,3.57-4.73l6.47-8.59a4.42,4.42,0,0,1,.54-.62c.28.27.52.51.72.74Zm32.93-5.4a8.72,8.72,0,1,0,17.43,0V43.1a34.4,34.4,0,0,0,4-.83,41.07,41.07,0,0,0,8.39-3.42,70.07,70.07,0,0,0,9-5.84c3.07-2.3,6.49-5.09,10.16-8.29a10.07,10.07,0,0,0,2.2-2.76,8.21,8.21,0,0,0,1-4,8.69,8.69,0,0,0-8.71-8.71,9.18,9.18,0,0,0-5.89,2.29l-.09.07c-4.14,3.63-7.63,6.48-10.37,8.47A39.75,39.75,0,0,1,417,24.24a17,17,0,0,1-5.07,1.44,62.36,62.36,0,0,1-6.38.28,58.09,58.09,0,0,1-6.43-.32,18.67,18.67,0,0,1-5.29-1.51A38.61,38.61,0,0,1,386.89,20c-2.74-2-6.19-4.79-10.27-8.37l-.12-.1-.12-.09a14.74,14.74,0,0,0-2.18-1.36,7.89,7.89,0,0,0-3.56-.81A8.71,8.71,0,0,0,361.92,18a8.2,8.2,0,0,0,1,4,10.14,10.14,0,0,0,2.21,2.76c3.71,3.25,7.12,6.05,10.13,8.32a71.58,71.58,0,0,0,8.81,5.78,39.33,39.33,0,0,0,8.26,3.43,30.34,30.34,0,0,0,4.34.88Z"
              />
              <path
                className="cls-4"
                d="M102.63,34.68a31.12,31.12,0,0,1-1.58,9.64,29.21,29.21,0,0,1-4.6,8.82,25.23,25.23,0,0,1-7.31,6.5,18.82,18.82,0,0,1-9.71,2.52H21.94a18.39,18.39,0,0,1-9.2-2.83,25.16,25.16,0,0,1-6.87-6.5,29.85,29.85,0,0,1-4.36-8.7A31.49,31.49,0,0,1,0,34.68,31.14,31.14,0,0,1,1.58,25a29.33,29.33,0,0,1,4.6-8.82,25.24,25.24,0,0,1,7.31-6.5A18.82,18.82,0,0,1,23.2,7.19H80.69A18.43,18.43,0,0,1,89.89,10a25,25,0,0,1,6.87,6.49,30.1,30.1,0,0,1,4.36,8.7A31.55,31.55,0,0,1,102.63,34.68ZM83.84,48.48a13,13,0,0,0,4.35-3.84,13.64,13.64,0,0,0,2.15-5,24.33,24.33,0,0,0,.56-5,24.18,24.18,0,0,0-.56-5,13.5,13.5,0,0,0-2.15-5,12.9,12.9,0,0,0-4.35-3.85,14.16,14.16,0,0,0-7.06-1.57H25.85a14.14,14.14,0,0,0-7.13,1.57,13.13,13.13,0,0,0-4.28,3.85,13.5,13.5,0,0,0-2.15,5,24.18,24.18,0,0,0-.56,5,24.33,24.33,0,0,0,.56,5,13.64,13.64,0,0,0,2.15,5,13.21,13.21,0,0,0,4.28,3.84,14,14,0,0,0,7.13,1.58H76.78A14.06,14.06,0,0,0,83.84,48.48Z"
              />
              <path
                className="cls-4"
                d="M204.72,9a6.05,6.05,0,0,0-10.34,4.28V27.76l12,7.35a.33.33,0,0,0,.1.06V13.24A5.83,5.83,0,0,0,204.72,9Zm-84.1-1.77A11.6,11.6,0,0,0,112,10.72a12,12,0,0,0-3.47,8.7V56.11a6.05,6.05,0,1,0,12.1,0V31.27a35.58,35.58,0,0,1,.51-6.93c.33-1.6,1.13-2.4,2.39-2.4a5.78,5.78,0,0,1,2.35.72V12.23a16.22,16.22,0,0,1,.44-3.61,11.92,11.92,0,0,0-2.22-.93A12.39,12.39,0,0,0,120.62,7.19Zm70.86,40.22a6,6,0,0,1-2.59-.82q-1.57-.81-4-2.21L143.44,19a43.53,43.53,0,0,0-.24,5.06V33l44.62,27.28a12.37,12.37,0,0,0,6.56,1.89A11.58,11.58,0,0,0,203,58.63a11.77,11.77,0,0,0,2.53-3.79L192.74,47A2.13,2.13,0,0,1,191.48,47.41Z"
              />
              <path
                className="cls-4"
                d="M220.52,7.36a6.05,6.05,0,0,0-6.05,6.05V31A36.59,36.59,0,0,1,214,38c-.34,1.59-1.14,2.39-2.4,2.39a6,6,0,0,1-2.59-.82q-1.57-.81-4-2.21L147.14,1.89A12.08,12.08,0,0,0,144.05.5a12.32,12.32,0,0,0-3.46-.5A11.63,11.63,0,0,0,132,3.53a12,12,0,0,0-3.47,8.7V56.1a6.53,6.53,0,0,0,1.94,4.78,6.08,6.08,0,0,0,8.22,0,6.5,6.5,0,0,0,2-4.78v-32a35.59,35.59,0,0,1,.5-6.93c.33-1.6,1.13-2.4,2.39-2.4a6,6,0,0,1,2.59.82c1.05.55,2.37,1.28,4,2.21l57.87,35.45a12.37,12.37,0,0,0,6.56,1.89,11.58,11.58,0,0,0,8.63-3.53,11.89,11.89,0,0,0,3.47-8.7V13.41a6.05,6.05,0,0,0-6-6Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
